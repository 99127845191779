import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import * as Sentry from '@sentry/angular';
import { TranslateService } from '@ngx-translate/core';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha-2';
import { lastValueFrom } from 'rxjs';

import { EnvironmentService } from '@environments/environment.service';
import { environment } from '@environments/environment';

import { HttpInterceptorService } from './services/http-interceptor/http-interceptor.service';

registerLocaleData(localeNl);

export function initEnvironmentService(environmentService: EnvironmentService) {
  return async () => environmentService.init();
}

export function initLanguageService(translateService: TranslateService) {
  return async () => {
    translateService.addLangs(['de', 'en', 'nl']);
    translateService.setDefaultLang('nl');
    return lastValueFrom(translateService.use('nl'));
  };
}

@NgModule()
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,

      providers: [
        EnvironmentService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true,
        },

        {
          provide: APP_INITIALIZER,
          useFactory: initEnvironmentService,
          deps: [EnvironmentService],
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initLanguageService,
          deps: [TranslateService],
          multi: true,
        },
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler(),
        },
        {
          provide: RECAPTCHA_V3_SITE_KEY,
          useValue: environment.recaptcha.siteKey,
        },
      ],
    };
  }
}
