import { Component } from '@angular/core';

export interface HeroBannerTheme {
  accentLight: string;
  accentDark: string;
}

@Component({
  selector: 'lg-hero-banner',
  templateUrl: './hero-banner.component.html',
})
export class LgHeroBannerComponent {
  heroBannerImageURLs: string[];

  heroBannerThemes: string[];

  activeHeroBannerImage?: string;

  activeHeroBannerTheme: string;

  constructor() {
    this.heroBannerImageURLs = [
      'assets/images/doodles/exact-doodle-nohands.png',
      'assets/images/doodles/exact-doodle-nohands-v2.png',
      'assets/images/doodles/exact-doodle-nohands-v3.png',
      'assets/images/doodles/exact-doodle-nohands-v4.png',
      'assets/images/doodles/exact-doodle-nohands-v5.png',
      'assets/images/doodles/exact-doodle-accountancy.png',
      'assets/images/doodles/exact-doodle-accountancy-v2.png',
      'assets/images/doodles/exact-doodle-accountancy-v3.png',
      'assets/images/doodles/exact-doodle-accountancy-v4.png',
      'assets/images/doodles/exact-doodle-accountancy-v5.png',
      'assets/images/doodles/exact-doodle-accountancy-v6.png',
      'assets/images/doodles/exact-doodle-accountancy-v7.png',
      'assets/images/doodles/exact-doodle-licensing-model.png',
      'assets/images/doodles/exact-doodle-business-owner.png',
      'assets/images/doodles/exact-doodle-accountancy-day.png',
      'assets/images/doodles/exact-doodle-accountancy-day-v2.png',
      'assets/images/doodles/exact-doodle-break-coffee.png',
      'assets/images/doodles/exact-doodle-business-manager.png',
      'assets/images/doodles/exact-doodle-bank-connection.png',
      'assets/images/doodles/exact-doodle-consultancy.png',
      'assets/images/doodles/exact-doodle-ux-design.png',
      'assets/images/doodles/exact-doodle-engage.png',
      'assets/images/doodles/exact-doodle-engage-v2.png',
      'assets/images/doodles/exact-doodle-functionality.png',
      'assets/images/doodles/exact-doodle-functionality-demoday.png',
      'assets/images/doodles/exact-doodle-lead-dev.png',
      'assets/images/doodles/exact-doodle-lead-dev-v2.png',
      'assets/images/doodles/exact-doodle-progress.png',
      'assets/images/doodles/exact-doodle-sales.png',
      'assets/images/doodles/exact-doodle-webinar.png',
      // 'assets/images/doodles/exact-doodle-seasonal-autumn.png',
      // 'assets/images/doodles/exact-doodle-seasonal-autumn-v2.png',
      // 'assets/images/doodles/exact-doodle-seasonal-autumn-v3.png',
      // 'assets/images/doodles/exact-doodle-seasonal-autumn-v4.png',
    ];

    this.heroBannerThemes = ['blue', 'yellow', 'brown'];

    this.activeHeroBannerTheme = this.getRandomizedAccentColors();

    this.activeHeroBannerImage = this.getRandomizedHeroBannerImage();
  }

  getRandomizedAccentColors(): string {
    const randomIndex = Math.floor(Math.random() * this.heroBannerThemes.length);

    return this.heroBannerThemes[randomIndex];
  }

  getRandomizedHeroBannerImage(): string {
    const randomIndex = Math.floor(Math.random() * this.heroBannerImageURLs.length);

    return this.heroBannerImageURLs[randomIndex];
  }
}
