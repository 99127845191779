import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LgButtonModule } from '@components/button/button.module';
import { LgCardModule } from '@components/card/card.module';
import { LgLinkModule } from '@components/link/link.module';
import { LgParagraphModule } from '@components/paragraph/paragraph.module';
import { LgAnimationsModule } from '@utils/animations/animations.module';
import { LgSpacerModule } from '@utils/spacer/spacer.module';

import { LgHeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { LgLayoutComponent } from './layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LgCardModule,
    LgLinkModule,
    LgButtonModule,
    LgParagraphModule,
    LgSpacerModule,
    LgAnimationsModule,
  ],
  declarations: [LgLayoutComponent, LgHeroBannerComponent],
  exports: [LgLayoutComponent],
})
export class LgLayoutModule {}
