<section
  class="w-[420px] overflow-hidden rounded-md border border-[#DADEDE] bg-white drop-shadow-sm"
  [ngClass]="{
    'p-[12px]': padding === 'small',
    'p-[24px]': padding === 'medium',
    'p-[32px]': padding === 'large'
  }"
  [ngStyle]="{ height: 'auto', 'max-height': '620px', transition: 'max-height 10s ease-in-out' }"
>
  <ng-container *ngIf="hasBackbone">
    <span class="bg-accent-red absolute left-0 top-0 z-50 h-1/3 w-[6px]"></span>
    <span class="bg-accent-yellow absolute left-0 top-1/3 z-50 h-1/3 w-[6px]"></span>
    <span class="bg-accent-blue absolute left-0 top-2/3 z-50 h-1/3 w-[6px]"></span>
  </ng-container>

  <ng-content></ng-content>
</section>
