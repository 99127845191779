import { DatePipe } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RecaptchaV3Module } from 'ng-recaptcha-2';

import { EnvironmentService } from '@environments/environment.service';
import { LgLayoutModule } from '@layout/layout.module';
import { LgStoreModule } from '@store/index';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CustomTranslateLoader } from './core/services/custom-translate-loader/custom-translate-loader.service';

const disableAnimations =
  !('animate' in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent)) ||
  window.matchMedia('(prefers-reduced-motion)').matches;

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    RouterModule,
    RecaptchaV3Module,
    AppRoutingModule,
    CoreModule.forRoot(),
    LgStoreModule,
    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [EnvironmentService, HttpClient],
      },
    }),
    LgLayoutModule,
  ],
  providers: [DatePipe, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
