<div class="bg-white">
  <div class="relative">
    <div class="max-w-screen absolute inset-x-0 overflow-x-hidden">
      <lg-hero-banner></lg-hero-banner>
    </div>

    <img
      alt="Exact Logo"
      class="absolute left-[24px] top-[32px] z-50 w-[96px] hover:cursor-pointer sm:left-[48px] sm:w-[120px]"
      [src]="exactLogo"
      (click)="onNavigateToHome()"
    />
  </div>

  <div class="relative h-screen w-screen">
    <div class="absolute left-1/2 top-[240px] -translate-x-1/2 sm:top-[360px]">
      <lg-shake-animation>
        <lg-card [minHeight]="true" [hasBackbone]="true">
          <img
            alt="Exact Jaarekening & Fiscaal Logo"
            class="mb-md"
            [src]="exactJaarrekeningFiscaalLogo"
          />
          <div class="-mx-[32px]">
            <router-outlet></router-outlet>
          </div>
        </lg-card>

        <lg-spacer [size]="'small'"></lg-spacer>

        <!-- <lg-card [padding]="'small'">
          <lg-paragraph [size]="'small'">
            iDEAL betalingen door uw cliënten voor aangiftes omzetbelasting zijn nu mogelijk met
            <span class="font-bold">Samenwerken Uitgebreid</span>.
            <lg-link (click)="onNavigateToPromo()" [size]="'small'">Lees meer</lg-link>
          </lg-paragraph>
        </lg-card> -->
      </lg-shake-animation>
    </div>
    <ul
      class="absolute w-[420px] right-1/2 translate-x-1/2 text-center md:translate-x-0 md:right-[48px] bottom-[48px] flex gap-4 text-sm justify-center"
    >
      <li>
        <lg-link
          [appearance]="'tertiary'"
          [size]="'small'"
          href="https://www.exact.com/support"
          target="_blank"
          >Support</lg-link
        >
      </li>
      <li>
        <lg-link
          [appearance]="'tertiary'"
          [size]="'small'"
          href="https://status.exact.com/"
          target="_blank"
          >Status</lg-link
        >
      </li>
      <li>
        <lg-link
          [appearance]="'tertiary'"
          [size]="'small'"
          href="https://files.exact.com/static/downloads/terms-and-conditions/Privacy-Policy-for-Exact-Services-NL.pdf"
          target="_blank"
          >Privacy</lg-link
        >
      </li>
      <li>
        <lg-link
          [appearance]="'tertiary'"
          [size]="'small'"
          href="https://www.exact.com/disclaimer"
          target="_blank"
          >Disclaimer</lg-link
        >
      </li>
      <li>
        <lg-link
          [appearance]="'tertiary'"
          [size]="'small'"
          href="https://files.exact.com/static/downloads/terms-and-conditions/Exact-Online-Voorwaarden-Nederland.pdf"
          target="_blank"
          >Terms & Conditions</lg-link
        >
      </li>
    </ul>
  </div>
</div>
