import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Observable, Subject } from 'rxjs';

import { Environment } from './environment.model';

export let ENVIRONMENT: Environment;

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly isLoaded = new Subject<boolean>();

  constructor(private readonly http: HttpClient) {}

  loaded$(): Observable<boolean> {
    return this.isLoaded.asObservable();
  }

  async init(): Promise<unknown> {
    return new Promise((resolve) => {
      this.http
        .get<Environment>('environment.json')
        .subscribe((environment) => {
          ENVIRONMENT = { ...environment };

          if (environment && environment.sentryDsn) {
            Sentry.init({
              dsn: environment.sentryDsn,
              environment: environment.environment,
              release: `fgs@${environment.version}`,
            });
          }

          resolve(true);
          this.isLoaded.next(true);
        });
    });
  }
}
