import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
  AppState,
  EAuthenticationStep,
  LgAuthenticationActions,
  LgMigrationActions,
  LgUserActions,
} from '@store/index';

@Component({
  selector: 'lg-layout',
  templateUrl: './layout.component.html',
})
export class LgLayoutComponent {
  exactLogo: string;

  exactJaarrekeningFiscaalLogo: string;

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>
  ) {
    this.exactLogo = 'assets/images/exact-logo.png';

    this.exactJaarrekeningFiscaalLogo =
      'assets/images/exact-jaarrekening-fiscaal-logo.svg';
  }

  onNavigateToPromo(): void {
    window.open(
      'https://www.exact.com/nl/producten/accountancy/collaboration',
      '_blank'
    );
  }

  onNavigateToHome(): void {
    this.store.dispatch(
      LgAuthenticationActions.AuthenticateClearLoginSessionPending()
    );
    this.store.dispatch(LgUserActions.ResetUser());
    this.store.dispatch(LgMigrationActions.MigrateResetState());

    this.store.dispatch(
      LgAuthenticationActions.SetAuthenticationFlow({
        activeAuthenticationStep: EAuthenticationStep.Email,
        progress: 'prevStep',
      })
    );

    this.router.navigateByUrl('/', { replaceUrl: true });
  }
}
