<div class="relative h-[320px] sm:h-[540px]">
  <div
    class="absolute left-0 top-0 h-[320px] w-full sm:h-[540px]"
    [ngClass]="{
      'bg-[#E7ECF0]': activeHeroBannerTheme === 'blue',
      'bg-[#F9F2DC]': activeHeroBannerTheme === 'yellow',
      'bg-[#F8F5F3]': activeHeroBannerTheme === 'brown'
    }"
  ></div>
  <div
    class="absolute left-2/3 top-0 hidden h-[320px] w-1/3 sm:block sm:h-[540px]"
    [ngClass]="{
      'bg-[#CDD8E1]': activeHeroBannerTheme === 'blue',
      'bg-[#F1DFA9]': activeHeroBannerTheme === 'yellow',
      'bg-[#E3DBCF]': activeHeroBannerTheme === 'brown'
    }"
  ></div>

  <img
    alt="Exact Doodle"
    class="absolute right-1/3 top-0 h-[320px] w-auto translate-x-1/2 object-cover sm:h-[540px] sm:min-w-[720px]"
    [src]="activeHeroBannerImage"
  />
</div>
