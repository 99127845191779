import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LgShakeAnimationComponent } from './shake-animation/shake-animation.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LgShakeAnimationComponent],
  exports: [LgShakeAnimationComponent],
})
export class LgAnimationsModule {}
