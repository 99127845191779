import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { QueryGuard } from './guards/query.guard';

const routes: Routes = [
  {
    path: '',
    canDeactivate: [QueryGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren: async () =>
          import('@features/traditional-login/traditional-login.module').then(
            (x) => x.LgTraditionalLoginModule
          ),
      },

      {
        path: 'redirect',
        loadChildren: async () =>
          import('@features/redirect/redirect.module').then(
            (x) => x.LgRedirectModule
          ),
      },
      {
        path: 'error',
        loadChildren: async () =>
          import('@features/error/error.module').then((x) => x.LgErrorModule),
      },
      {
        path: 'forbidden',
        loadChildren: async () =>
          import('@features/forbidden/forbidden.module').then(
            (x) => x.LgForbiddenModule
          ),
      },
      {
        path: '**',
        redirectTo: '/',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
