import { Component, Input } from '@angular/core';

@Component({
  selector: 'lg-card',
  templateUrl: './card.component.html',
})
export class LgCardComponent {
  @Input() minHeight?: boolean;

  @Input() padding?: 'small' | 'medium' | 'large' | 'none' = 'large';

  @Input() hasBackbone?: boolean;
}
