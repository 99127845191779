import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ParamMap,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

/** Query guard to keep query string params in url when routing to different urls. */
@Injectable({ providedIn: 'root' })
export class QueryGuard {
  constructor(private readonly router: Router) {}

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (currentRoute.root.queryParamMap.keys.length === 0) {
      return true;
    }

    if (nextState) {
      if (
        this.compareParamMaps(
          currentState.root.queryParamMap,
          nextState.root.queryParamMap
        )
      ) {
        return true;
      }
      this.router.navigate([nextState.url], {
        preserveFragment: true,
        queryParamsHandling: 'preserve',
      });

      return false;
    }
    return true;
  }

  private compareParamMaps(mapA: ParamMap, mapB: ParamMap): boolean {
    return mapA.keys.map((key) => mapB.has(key)).every((it) => it);
  }
}
