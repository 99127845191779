import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import {
  ENVIRONMENT,
  EnvironmentService,
} from '@environments/environment.service';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateLoader implements TranslateLoader {
  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly http: HttpClient
  ) {}

  getTranslation(lang: string): Observable<unknown> {
    lang = lang ?? 'nl';

    return new Observable<unknown>((obs) => {
      if (ENVIRONMENT === undefined) {
        return this.environmentService.loaded$().subscribe(() =>
          this.http
            .get(`./assets/i18n/${lang}.json?v=${ENVIRONMENT.version}`)
            .subscribe((t) => {
              obs.next(t);
              obs.complete();
            })
        );
      }
      return this.http
        .get(`./assets/i18n/${lang}.json?v=${ENVIRONMENT.version}`)
        .subscribe((t) => {
          obs.next(t);
          obs.complete();
        });
    });
  }
}
