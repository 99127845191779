import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SigninFgResult } from '@api';
import {
  AppState,
  LgAuthenticationSelectors,
  LgAuthenticationState,
  LgMigrationSelectors,
  LgMigrationState,
} from '@store/index';

@Component({
  selector: 'lg-shake-animation',
  templateUrl: './shake-animation.component.html',
})
export class LgShakeAnimationComponent {
  @Input() triggered = true;

  ESigninFgResult = SigninFgResult;

  constructor(private readonly store: Store<AppState>) {}

  get authentication$(): Observable<LgAuthenticationState> {
    return this.store.select(LgAuthenticationSelectors.selectAuthentication);
  }

  get migration$(): Observable<LgMigrationState> {
    return this.store.select(LgMigrationSelectors.selectMigration);
  }
}
