import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private readonly router: Router) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let headers: HttpHeaders =
      req.body instanceof FormData
        ? req.headers
        : req.headers.set('Content-Type', 'application/json');

    headers = headers.append('Accept', 'application/json');

    return next
      .handle(
        req.clone({
          url: `${req.url}`,
          headers,
        })
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (
            err &&
            err.message &&
            err.message.toLowerCase().includes('chunk')
          ) {
            window.location.href = this.router.url;
          }

          // Canceled request, blocked by cors, adblock, etc
          if (err.status === 0 || err.status === undefined) {
            console.error(err);
            return NEVER;
          }

          if (err.status === 400 || err.status === 500 || err.status === 504) {
            this.router.navigate(['/error']);
          }

          if (err.status === 403) {
            this.router.navigate(['/forbidden']);
          }

          return throwError(() => err);
        })
      );
  }
}
